<template>
  <div class="container-alert">
    <template v-for="(alert, index) in alerts">
      <b-alert
        :key="index"
        :show="alert.show_alert"
        :variant="alert.variant || 'danger'"
        :dismissible="alert.dismiss_btn"
        @dismissed="onDismissed(index)"
        class="alert-position"
      >
        <div class="text-left">
          <span
            v-b-tooltip.left.v-secondary.noninteractive="alert.tooltip_text"
          >
            ⚠️
          </span>
          <span>{{ alert.title_alert }}</span>
        </div>
      </b-alert>
    </template>
  </div>
</template>

<script>
export default {
  name: "GenericAlertBox",
  props: {
    alerts: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  methods: {
    onDismissed(index) {
      // Marca la alerta como no visible al cerrarla
      this.$set(this.alerts[index], "show_alert", false);
    },
  },
  created() {},
};
</script>

<style scoped>
.container-alert {
  position: fixed;
  top: 100px;
  right: 5px;
  z-index: 1001;
}
</style>